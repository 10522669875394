// Core
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { Form, Field } from 'react-final-form';
// Parts
import Box from '@mui/material/Box';
import Input from '../_Form/Input';
import Button from '../Button';
// Style
import './style.scss';
import { composeValidators, isEmail, isRequired } from "../_Form/validators";

type questionFormType = {
  texts?: {
    title?: string
    text?: string
    btnText?: string
    messageInputPlaceholder?: string
    nameInputPlaceholder?: string
    emailInputPlaceholder?: string
  }
  formSuccessText?: string
  formErrorText?: string
  loading: boolean
  submitForm: (value: { [key: string]: string; }) => void
}

const QuestionForm:FC<questionFormType> = (props) => {
  const {
    texts,
    submitForm,
    formSuccessText,
    formErrorText,
    loading,
  } = props;
  const { t } = useTranslation();
  const normalizeTitle = texts?.title || t('questionForm.title');
  const normalizeText = texts?.text || t('questionForm.text');
  const normalizeMessageInputPlaceholder = texts?.messageInputPlaceholder || t('questionForm.messageInputPlaceholder');
  const normalizeNameInputPlaceholder = texts?.nameInputPlaceholder || t('questionForm.nameInputPlaceholder');
  const normalizeEmailInputPlaceholder = texts?.emailInputPlaceholder || t('questionForm.nameEmailPlaceholder');
  const normalizeBtnText = texts?.btnText || t('questionForm.btnText');

  return (
    <div className="question-form">
      <div className="question-form_container">
        <div className="question-form_img" />
        <div className="question-form_content">
          {formSuccessText
            ? <div className="question-form_success-text">{formSuccessText}</div>
            : <>
              <div className="question-form_title">
                {normalizeTitle}
              </div>
              <div className="question-form_text">
                {normalizeText}
              </div>
              <Form
                onSubmit={submitForm}
                render={(
                  {
                    handleSubmit,
                    submitting,
                    pristine
                  }
                ) => (
                  <form className="question-form_form" onSubmit={handleSubmit}>
                    <Field
                      name="message"
                      multiline
                      rows={4}
                      component={Input}
                      validate={composeValidators((value) => isRequired(value, 'Необходимо заполнить поле «Ваше сообщение».'))}
                      label={normalizeMessageInputPlaceholder}
                    />
                    <Box sx={{ marginTop: '8px' }}>
                      <Field
                        name="name"
                        component={Input}
                        validate={composeValidators((value) => isRequired(value, 'Необходимо заполнить поле «Ваше имя».'))}
                        label={normalizeNameInputPlaceholder}
                      />
                    </Box>
                    <Box sx={{ marginTop: '8px' }}>
                      <Field
                        name="email"
                        component={Input}
                        validate={composeValidators((value) => isRequired(value, 'Необходимо заполнить поле «E-mail».'), isEmail)}
                        label={normalizeEmailInputPlaceholder}
                      />
                    </Box>
                    {formErrorText && <div className="question-form_error-text">{formErrorText}</div>}
                    <Button variant="secondary" disabled={submitting || pristine || loading} loading={loading} submit>
                      {normalizeBtnText}
                    </Button>
                  </form>
                )}
              />
            </>
          }
        </div>
      </div>
    </div>
  );
};

QuestionForm.defaultProps = {
  texts: {
    title: undefined,
    text: undefined,
    btnText: undefined,
    messageInputPlaceholder: undefined,
    nameInputPlaceholder: undefined,
    emailInputPlaceholder: undefined,
  },
  formSuccessText: undefined,
  formErrorText: undefined,
};

export default QuestionForm;
