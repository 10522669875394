// Core
import React, { FC } from 'react';
import { useSelector, useDispatch } from "react-redux";
// Global file and config
import { getServerDataValue } from '../../../../../../../new-moyo/src/js/native/helpers/storage.js';
// Parts
import OrderStatusItem from './OrderStatusItem';
import QuestionForm from '../../../../components/QuestionForm';
// Engine
import { postOrderStatusQuestionFormAsync } from "../../../../../engine/core/orderStatus/saga/asyncAction";
import { selectorsOrderStatus } from "../../../../../engine/core/orderStatus/selectors";

const OrderStatusContent:FC = () => {
  const orderData = useSelector(selectorsOrderStatus.data);
  const questionsForm = {
    loading: useSelector(selectorsOrderStatus.questionsForm.loading),
    successText: useSelector(selectorsOrderStatus.questionsForm.successText),
    errorText: useSelector(selectorsOrderStatus.questionsForm.errorText),
  };
  const orderDataTexts = orderData?.texts;
  const dispatch = useDispatch();
  const orderItems = Object.values(orderData?.items || []);
  const headerInfo = getServerDataValue('headerInfo') || {};
  const { firstname, csrf = '' } = headerInfo;

  const onSubmitForm = (value: { [key: string]: string; }) => {
    if (orderData.orderNumber) {
      dispatch(postOrderStatusQuestionFormAsync({
        'mailParams[order_id]' : orderData.orderNumber,
        'mailParams[comment]' : value.message,
        'mailParams[email]' : value.email,
        'mailParams[name]' : firstname,
        'YII_CSRF_TOKEN': csrf
      }))
    }
  }

  return (
    <>
      <div className="order-status_items">
        {orderItems.map((orderItem) => <OrderStatusItem
          key={orderItem.id}
          img={orderItem.img}
          name={orderItem.name}
          orderDataTexts={orderItem.orderDataTexts}
          count={orderItem.count}
          oldPrice={orderItem.oldPrice}
          price={orderItem.price}
          services={orderItem.services}
          url={orderItem.url}
          totalPrice={orderItem.totalPrice}
        />)}
      </div>
      <div className="order-status_details">
        <div className="order-status_details_sum">
          <div className="order-status_details_sum_title">
            {orderDataTexts?.orderSum}
          </div>
          <div
            className="order-status_details_sum_value"
            dangerouslySetInnerHTML={{ __html: orderData?.orderSum || '' }}
          />
        </div>
        {!!orderData?.delivery && (
          <div className="order-status_details_delivery-sum">
            <div className="order-status_details_delivery-sum_title">
              {orderDataTexts?.deliverySum}
            </div>
            <div className={`order-status_details_delivery-sum_value ${orderData?.freeDelivery ? 'free' : ''}`}>
              {orderData?.delivery}
            </div>
          </div>
        )}
        {!!orderData?.liftCost && (
          <div className="order-status_details_delivery-sum">
            <div className="order-status_details_delivery-sum_title">
              {orderDataTexts?.liftCost}
            </div>
            <div className="order-status_details_delivery-sum_value">
              {orderData?.liftCost}
            </div>
          </div>
        )}
        <div className="order-status_details_total-sum">
          <div className="order-status_details_total-sum_title">
            {orderDataTexts?.totalSum}
          </div>
          <div
            className="order-status_details_total-sum_value"
            dangerouslySetInnerHTML={{ __html: orderData?.totalSum || '' }}
          />
        </div>
        {!!orderData?.cashback && (
          <div className="order-status_details_cashback-wrap">
            <div className="order-status_details_cashback">
              <div className="order-status_details_cashback_text">
                {orderDataTexts?.cashbackText}
              </div>
              <div className="order-status_details_cashback_value">
                {orderData?.cashback}
              </div>
            </div>
          </div>
        )}
      </div>
      <QuestionForm
        texts={orderDataTexts?.questionForm}
        submitForm={onSubmitForm}
        formSuccessText={questionsForm.successText}
        formErrorText={questionsForm.errorText}
        loading={questionsForm.loading}
      />
    </>
  )
}

export default OrderStatusContent;
